<template>
    <div class="google-map" ref="googleMap" style="width: 100%; height: 100%;"></div>
</template>

<script>
    import GoogleMapsApiLoader from 'google-maps-api-loader'
    import swal from 'sweetalert2'

    export default {
        name: 'Mapa',
        props: {
            edit: {
                required: false,
            },
            lat: {
                required: false,
            },
            lng: {
                required: false,
            }
        },
        async mounted() {
            // Load google map component
            const googleMapApi = await GoogleMapsApiLoader({
              apiKey: window.mapKey
            });
            this.google = googleMapApi;

            this.initializeMap();
        },
        data() {
            return {
                google: null,
                map: null,
                markers: [],
                mapConfig: {
                    streetViewControl: false,
                    zoom: 10,
                    center: { lat: 19.43268555173635, lng: -99.13338149768724 },
                },
            }
        },
        methods: {
            /**
             * Load the map object in the html.
             *
             * @return void
             */
            initializeMap() {
                const mapContainer = this.$refs.googleMap;
                this.map = new this.google.maps.Map(
                    mapContainer, this.mapConfig
                );

                if (this.edit) {
                    var self = this;

                    // Add listener to click event in the map
                    this.map.addListener('click', function(event) {
                        if (self.markers.length) {
                            swal.fire('Wow!', 'Solo puedes ingresar una ubicación.', 'warning');
                        } else {
                            self.getMapPoint(event.latLng);
                        }
                    });
                }

                if(parseFloat(this.lat) && parseFloat(this.lng)) {
                    this.showPointMap();
                }
            },
            /**
             * Get the the last point clicked in the map.
             * 
             * @param  Object  value
             * @return void
             */
            getMapPoint (value) {
                // Set the point to data current
                this.mapPoint = value;

                // Display alert
                swal.fire({
                    title: '¿Estas seguro de agregar esta ubicación?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Agregar',
                    cancelButtonText: 'Cancelar',
                }).then((result) => {
                    if (result.value) {
                        this.insertPoint();
                    }
                });
            },
            /**
             * Insert the current point as marker and new polygon point.
             * 
             * @return void
             */
            insertPoint () {
                // Set the point as marker in the map.
                this.setMarkers(this.mapPoint.lat(), this.mapPoint.lng());
            },
            /**
             * Push the point in the marker array with google map format.
             *
             * @param  int  latitude
             * @param  int  longitude
             * @return void
             */
            setMarkers (latitude, longitude) {
                var marker = new this.google.maps.Marker({
                    position: {
                        lat: latitude,
                        lng: longitude
                    },
                    label: this.markers.length+1+'',
                    map: this.map,
                    draggable: true
                });
                this.map.setZoom(15);
                this.map.setCenter(marker.getPosition());

                if (this.edit) {
                    var self = this;
                    // Delete marker if double clicked over
                    marker.addListener('dblclick', function() {
                        swal.fire({
                            title: '¿Estas seguro de eliminar esta ubicación?',
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonText: 'Agregar',
                            cancelButtonText: 'Cancelar',
                        }).then((result) => {
                            self.markers[0].setMap(null);
                            self.markers = [];
                        });
                    });
                    marker.addListener('dragend',function(event){
                        $('#institucion_latitud').val(event.latLng.lat());
                        $('#institucion_longitud').val(event.latLng.lng())
                    })
                }

                this.markers.push(marker);
            },
            /**
             * Draw the markers and the polygon in the map
             *
             * @param  Object  map
             * @return void
             */
            setMapOnAll(map) {
                // Remove all markers and polygon from the map and the array.
                this.markers.forEach((marker) => {
                    marker.setMap(map);
                });
                this.markers = [];
            },
            /**
             * Display the specific geofence data.
             * 
             * @return void
             */
            showPointMap () {
                // Clean the map
                this.setMapOnAll(null);

                this.setMarkers(parseFloat(this.lat), parseFloat(this.lng));
            },
        },
        watch: {
            'markers': function (newVal, oldVal) {
                if (newVal.length) {
                    $('#institucion_latitud').val(newVal[0].position.lat);
                    $('#institucion_longitud').val(newVal[0].position.lng);
                } else {
                    $('#institucion_latitud').val('');
                    $('#institucion_longitud').val('');
                }
            }
        }
    }
</script>
