/*
 |--------------------------------------------------------------------------
 | Document Ready Function
 |--------------------------------------------------------------------------
 */
$(document).ready(function() {
    $('#usuario_supervisor').on('change', function() {
        if(this.value === '1') {
            $('#div_perfil').removeClass('d-none');
        } else {
            $('#div_perfil').addClass('d-none');
        }
    });

    $('.btn-status-user').on('click', function() {

        HoldOn.open({ message: 'Procesando datos, por favor espere.' });

        if($(this).data('action') === 'restore') {
            axios.post('/colaboradores/'+$(this).data('usuario'))
                .then((response) => {
                    window.location.reload();
                })
                .catch((error) => {
                    HoldOn.close();
                });
            
        } else {
            //inactive
            axios.delete('/colaboradores/'+$(this).data('usuario'))
                .then((response) => {
                    window.location.reload();
                })
                .catch((error) => {
                    HoldOn.close();
                });
        }
    });

    window.chageStatus = function (user) {
        $('.nombre_usuario').text(user.nombre+' '+user.apellido);
        $('.btn-status-user').data('usuario', user.id);
        
        if (user.deleted_at) {
            $('#restoreUser').modal();
        } else {
            $('#deleteUser').modal();
        }
    }

    $(".btn-new-user").on('click', function(event){
        $('#user_method').val('POST');
        $('#formCollaborators').prop('action', '/colaboradores');
        // Reset checked boxs
        $('input[name="perfiles[]"]').prop('checked', false);

        $('#usuario_nombre').val('');
        $('#usuario_apellido').val('');
        $('#usuario_email').val('');
        $('#usuario_rol').val('');
        $('#usuario_supervisor').val(0);
        $('#proyecto_usuario').val(null);
        $('#div_perfil').addClass('d-none');
        $('#div_projects').addClass('d-none');

        $('#addUserLabel').text('Agregar Colaborador');

        $('#addUser').modal();
    });

    $(".btn-edit-user").on('click', function(event){
        event.preventDefault();
        $('#user_method').val('PUT');
        $('#formCollaborators').prop('action', '/colaboradores/'+$(this).data('usuario'));

        // Reset checked boxs
        $('input[name="perfiles[]"]').prop('checked', false);

        $('#addUserLabel').text('Editar Colaborador');

        $('#addUser').modal();

        HoldOn.open({ message: 'Procesando datos, por favor espere.' });

        axios.get('/colaboradores/'+$(this).data('usuario'))
            .then((response) => {
                
                $('#usuario_nombre').val(response.data.usuario.nombre);
                $('#usuario_apellido').val(response.data.usuario.apellido);
                $('#usuario_email').val(response.data.usuario.email);
                $('#usuario_rol').val(response.data.usuario.rol_web);
                $('#usuario_supervisor').val(+response.data.usuario.es_supervisor);
                $('#proyecto_usuario').val(response.data.usuario.project_user);

                if (response.data.usuario.es_supervisor) {
                    $('#div_perfil').removeClass('d-none');
                } else {
                    $('#div_perfil').addClass('d-none');
                }

                if (response.data.usuario.rol_web == 50) {
                    $('#div_projects').removeClass('d-none');
                } else {
                    $('#div_projects').addClass('d-none');
                }

                $.each(response.data.usuario.perfiles, function(index, value) {
                    $('#perfil_'+value.id).prop('checked', true);
                });

                HoldOn.close();

            })
            .catch((error) => {
                HoldOn.close();
            });
    });
});
