/*
 |--------------------------------------------------------------------------
 | Document Ready Function
 |--------------------------------------------------------------------------
 */
$(document).ready(function() {

    /**
     * Show/hide all sidebar
     */
    $("#menu-toggle").click(function(e) {
      e.preventDefault();
      $("#app").toggleClass("toggled");
    });

    /**
     * Options sibebar items
     */
    $('.menu a.btn-colapse').click(function(e) {
        e.preventDefault();
        console.log($(this));

        if ($(this).parent().hasClass('active')) {
            $(this).parent().removeClass('active');
            $(this).next().slideUp();
        } else {
            $('.menu li ul').slideUp();
            $('.menu li').removeClass('active');
            $(this).parent().addClass('active');
            $(this).next().slideDown();
        }
    });

    $('.datepickerbutton').datetimepicker({format: 'YYYY-MM-DD'});

    $('a').on('click', function (event) {
          let $this = $(this),
              method = $this.data('method'),
              token = $('meta[name="csrf-token"]').attr('content'),
              action = $this.attr('href');

          if (typeof(method) == 'undefined' || method === 'GET') return;

          event.preventDefault();

          // Pedir confirmación al usuario antes de borrar.
          if (method === 'DELETE') {
              if (! confirm('¿Deseas borrar este contenido?')) {
                  return;
              }
          }

          $('<form></form>', { action: action, method: 'POST' })
              .append($('<input>', { name: '_token', value: token }))
              .append($('<input>', { name: '_method', value: method }))
              .addClass('hidden')
              .appendTo('body')
              .submit();
      });
});
