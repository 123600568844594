<template>
    <div class="custom-control custom-switch p-0" @click="goToAction(item)">
        
        <img class="w-20" src="/ico/boton_activo.png" title="Desactivar" v-if="status"/>
        <img class="w-20" src="/ico/boton_inactivo.png" title="Activar" v-else/>

        <input type="checkbox" class=" d-none custom-control-input btn-status-user" :id="'switch'+item.id" disabled v-model="status">
        <label class="custom-control-label d-none" :for="'switch'+item.id" :class="status ? 'text-success' : 'text-danger'"></label>
    </div>
</template>

<script>
    export default {
        name: 'CustomSwitch',
        props: {
            item: {
                type: Object,
                required: true,
            },
            status: {
                required: true,
            },
        },
        methods: {
            goToAction(item) {
                window.chageStatus(item);
            }
        }
    }
</script>
