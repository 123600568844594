require('./bootstrap');

require('./plugins/holdon');
require('./plugins/eonasdan-bootstrap-datetimepicker/bootstrap-datetimepicker');

require('./html/inputs');
require('./html/colaboradores');

/*
 |--------------------------------------------------------------------------
 | Setup The Vue Instance
 |--------------------------------------------------------------------------
 |
 | Next, we will create a fresh Vue application instance and attach it to
 | the page. Then, you may begin adding components to this application
 | or customize the JavaScript scaffolding to fit your unique needs.
 |
 */

Vue.component('custom-switch', require('./components/custom/CustomSwitch.vue').default);
Vue.component('show-point-map', require('./components/showPointMap.vue').default);

const app = new Vue({
    el: '#app',
});
